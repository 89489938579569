import { useEffect, useState } from 'react';
import WidgetHeader from '../WidgetHeader';

import { PrixMoyCategorieData } from "../../models/shopData";
import dayjs from 'dayjs';

import WidgetSearchFrame from '../WidgetSearchFrame';
import { DashboardWidget, DashboardWidgetSlide } from '../../models/widget';
import { getPrixMoyCategorie } from '../../api/caisseApi';
import ChartBlock from '../ChartBlock';
import { getBarChartHeight } from '../../utils/Charts';
import useWindowDimensions, { useAppSelector } from '../../app/hooks';
import { smallWidth } from '../../utils/Const';
import { WidgetContent } from '../WidgetContent';
import { getDateAsString, getRefresh } from '../../utils/Widgets';

interface PrixMoyenCategoriesProps {
    shopId: number,
    widget: DashboardWidget
}

interface PrevisionsSortiesSlideProps {
    shopId: number;
    slide: DashboardWidgetSlide
    top: number;
    widget: DashboardWidget
}


export default function PrixMoyenCategories({shopId, widget} : PrixMoyenCategoriesProps) {

    const top: number = 10;
    const [slide, setSlide] = useState<DashboardWidgetSlide>({debut:'@EXERCICEDEBUT@', fin:dayjs().format('YYYY-MM-DD'), timestamp:null})

    const height :string = (useWindowDimensions().width < smallWidth ? 140 : 150 ) + getBarChartHeight(top) +'px';

    return (
       <div className="Widget" style={{height:height}}>
            <WidgetHeader title="Prix moyen par catégorie" widget={widget}/>
            {widget.search ? 
                <WidgetSearchFrame widget={widget} withDateFin={true}>
                    {widget.searchDateDebut && widget.searchDateFin ?
                        <PrixMoyCategoriesSlide 
                            widget={widget}
                            shopId={shopId} 
                            slide={{debut: widget.searchDateDebut, fin: widget.searchDateDebut, timestamp: ""}}
                            top={top} /> 
                        : null
                    }   
                </WidgetSearchFrame>
                :  
                <PrixMoyCategoriesSlide 
                    widget={widget}
                    shopId={shopId} 
                    slide={slide}
                    top={top} />
           
          }
       </div>
    )
}


function PrixMoyCategoriesSlide ({shopId, widget, slide, top} : PrevisionsSortiesSlideProps) {

    const [data, setData] = useState<PrixMoyCategorieData | null>(null);
    const [error, setError] = useState<boolean>(false);

    const groups = useAppSelector((state) => state.user.groups);
    const theme: string = groups.find(el => el.id === -shopId)?.color || 'blue';

    useEffect(() => {
        setData(null);
        setError(false);
        getPrixMoyCategorie(shopId, slide.debut, slide.fin, getRefresh(slide.timestamp, widget.refreshTime), top)
        .then((res) => {
            setData(res);
            slide.timestamp = res.timestamp;
        })
        .catch((err) => {
            console.log(err);
            setError(true);
        });

        
    }, [widget.refreshTime, slide.debut, slide.fin]);

    return (
        <WidgetContent 
        data={data} 
        widget={widget} 
        error={error} 
        date={getDateAsString(data?.dateDebut || null, data?.dateFin || null, useWindowDimensions().width < smallWidth)}>
            {data == null ? 
                null :
                <div>
                    <ChartBlock
                        style={{height: getBarChartHeight(top) +'px'}}
                        widget={widget}
                        libelle=""
                        chartTypes={[ 'radar', 'bar', 'polar']}
                        color={theme}
                        data={data.prixMoyCategories.map(el => {
                            return {
                                name:el.nom,
                                valueN:el.prixMoyN,
                                valueN_1:el.prixMoyN_1
                            }
                        })}/>
                </div>
            }
        </WidgetContent>
    )
}