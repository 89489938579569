import { Group } from "../models/group";

export async function updateGroup (group : Group): Promise<Group[]> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/groups/update', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(group),
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    let json = await response.json();
    return json.groups;
}

export async function createGroup (group : Group): Promise<Group[]> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/groups/new', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({name: group.name, color:group.color, shops:group.shops}),
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    let json = await response.json();
    return json.groups;
}

export async function deleteGroup (groupId : number): Promise<Group[]> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/groups/delete', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({id: groupId}),
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    let json = await response.json();
    return json.groups;
}
