import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useEffect, useState } from "react";
import { checkAuth } from "../api/authApi";
import { setUser } from "../features/UserSlice";

export default function ProtectedRoute() {

    const dispatch = useAppDispatch();
    const logged = useAppSelector((state) => state.user.active);
    const [loading, setLoading] = useState<boolean>(!logged);
    const location = useLocation();

    useEffect(() => { 
        if(!logged) {
            console.log('checking auth...');

           checkAuth()
           .then((res) => {
                if (res) dispatch(setUser({email:res.email, shops:res.shops, multibase: res.multibase, groups:res.groups, widgets:res.widgets}));
                setLoading(false);
           })
           .catch((err) => {
                setLoading(false);
           })
        }

    }, [location.pathname, logged]);

    

    return loading ? null : (logged ? <Outlet/> : <Navigate to={'/login'}/>)

}