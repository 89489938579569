import ChartData from "../models/chartData";
import { BarChart } from "./Charts/BarChart"
import '../styles/chartBlock.css';
import { BarChartIcon, LineChartIcon, PolarChartIcon, RadarChartIcon } from "./Icons";
import { PolarChart } from "./Charts/PolarChart";
import { RadarChart } from "./Charts/RadarChart";
import { LineChart } from "./Charts/LineChart";
import { updateFavoriteChartType } from "../api/widgetsApi";
import { DashboardWidget } from "../models/widget";
import { useAppDispatch } from "../app/hooks";
import { setChartType } from "../features/DashboardSlice";
import { CSSProperties } from "react";
import { updateWidgets } from "../features/UserSlice";

interface chartBlockProps {
    widget: DashboardWidget;
    libelle: string;
    color: string;
    chartTypes: string[];
    data: ChartData[];
    style?: CSSProperties;
    sort?: boolean;
}

export default function ChartBlock({widget, data, libelle, chartTypes, color, style, sort=true} : chartBlockProps) {

    const dispatch = useAppDispatch();
    const currentChartType: string = widget.chartType || chartTypes[0];

    const onChangeChartType = (newChartType: string) => {
        if (widget.occurence === 0) {
            updateFavoriteChartType(widget.id, newChartType)
            .then(res => dispatch(updateWidgets(res)))
        }
        dispatch(setChartType({id:widget.id, occurence:widget.occurence, chartType:newChartType}));
    }


    return (
       <div className="ChartBlock">
            <div>{libelle}</div>
            <div className="chartButtons">
                {chartTypes.includes('bar') ? <button className={currentChartType == "bar" ? "selected" : ""} onClick={() => onChangeChartType('bar')}><BarChartIcon/></button> : null}
                {chartTypes.includes('polar') ? <button className={currentChartType == "polar" ? "selected" : "" } onClick={() => onChangeChartType('polar')}><PolarChartIcon/></button> : null}
                {chartTypes.includes('radar') ? <button className={currentChartType == "radar" ? "selected" : ""} onClick={() => onChangeChartType('radar')}><RadarChartIcon/></button> : null}
                {chartTypes.includes('line') ? <button className={currentChartType == "line" ? "selected" : ""} onClick={() => onChangeChartType('line')}><LineChartIcon/></button> : null}
            </div>

            {getChart(currentChartType, color, data, sort, style)}

       </div>
    )
}

function getChart(chartType : string, theme : string, data : ChartData[], sort: boolean, style?: CSSProperties) :React.ReactNode {

    switch(chartType) {
        case "bar":
            return <BarChart theme={theme} data={sort ? data.sort((a,b) => a.valueN > b.valueN ? 0 : 1) : data} style={style}/>
        case "polar":
            return <PolarChart theme={theme} data={sort ? data.sort((a,b) => a.valueN > b.valueN ? 1 : 0) : data} style={style}/>
        case "radar":
            return <RadarChart theme={theme} data={sort ? data.sort((a,b) => a.valueN > b.valueN ? 0 : 1) : data} style={style}/>
        case "line":
            return <LineChart theme={theme} data={data} style={style}/>
        default:
            return <div></div>
    }

}
