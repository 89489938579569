import { EChartOption } from "echarts";
import { ReactECharts } from "./ReactECharts";
import { CSSProperties } from "react";
import ChartData from "../../models/chartData";
import useWindowDimensions, { useWindowTheme } from "../../app/hooks";
import { smallWidth } from "../../utils/Const";
import { getChartColorTheme } from "../../utils/Charts";

interface LineChartProps {
    data: ChartData[];
    style?: CSSProperties;
    theme: string;
}



export function LineChart ({data, style, theme} : LineChartProps){

    const {width} = useWindowDimensions();
    const tooltip: EChartOption.Tooltip = {trigger: width > smallWidth ? 'axis':'none'}; 
    const fontSize = width < smallWidth ? 10 : 12;

    const colors = getChartColorTheme(theme, useWindowTheme() === "dark");

    style = {
        ...style,
        width:'96%'
    }
  
    let options: EChartOption = {
        tooltip: tooltip,
          legend: {
            show:true,
            itemWidth: 0,
            itemHeight: 0,
            selectedMode:false,
            data: [
                {
                    name: 'N',
                    textStyle: {
                        color: colors.backgroundN,
                        fontWeight: 500,
                        fontSize: 13
                    }
                },
                {
                    name: 'N-1',
                    textStyle: {
                        color: colors.backgroundN_1,
                        fontWeight: 500,
                        fontSize: 13
                    }
                }
            ]
        },
        grid: {
            left: 10,
            top: 20,
            right: 10,
            bottom: 10,
            containLabel:true,
        },
        xAxis: {
          type: 'category',
          data: data.map(el => el.name),
          axisLabel:{
            rotate:45,
            fontSize: fontSize
          }
        },
        yAxis: {
          min: Math.floor(Math.min(...data.map(el => Math.min(el.valueN, el.valueN_1)))*0.9),
          type: 'value',
          axisLabel:{
            fontSize: fontSize
          }
        },
        series: [
          {
            name:'N',
            data: data.map(el => el.valueN),
            lineStyle: {
                color: colors.legendN,
            },
            itemStyle: {
                color: colors.legendN,
            },
            type: 'line',
            smooth:true
          },
          {
            name: 'N-1',
            data: data.map(el => el.valueN_1),
            lineStyle: {
                color: colors.legendN_1,
                type:'dotted'
            },
            itemStyle: {
                color: colors.legendN_1,
            },
            type: 'line',
            smooth:true

          }
        ]
    }
  
    return  <ReactECharts option={options} style={style}/> 
  
}