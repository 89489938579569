

import '../styles/loaders.css';

interface SmallLoaderProps {
    style?: any
}

interface LoaderProps {
    style?: any
}


export function SmallLoader ({style} : SmallLoaderProps) {
    return (
        <div className="SmallLoader">
            <div className="loader" style={style}></div>
        </div>
    )
}

export function WidgetLoader ({style} : LoaderProps) {
    return (
        <div className='WidgetLoader'  style={style}>
            <span className='loader'></span>
        </div>
    )
}
