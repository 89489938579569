
import '../styles/dataBlock.css';
import { TrendDownIcon, TrendUpIcon } from './Icons';

interface DataBlockProps {
    libelle:string;
    valueN: number;
    valueN_1: number;
    unit?: string;
}

interface SimpleDataBlockProps  {
    libelle: string;
    value: number;
}

interface SingleDataBlockProps  {
    value: number;
    unit?: string;
}

interface SmallDataBlockContainerProps  {
    children : React.ReactNode[]
}

interface MeteoBlockProps {
    meteoN: number;
    meteoN_1: number
}

export function DataBlock({libelle, valueN, valueN_1}: DataBlockProps) {

    let pct = getPourcentageEvolution(valueN, valueN_1);

    return (
       <div className="DataBlock">
            <div>{libelle}</div>
            <div>
                <div className={'pct' + (pct >= 0 ? " up" : " down")}>
                    {(pct>0 ? "+":"") + pct.toFixed(1)+"%"}
                </div>
                <div className='values'>
                    <div>{valueN}</div>
                    <div>{valueN_1}</div>
                </div>
            </div>
       </div>
    )
}


export function SimpleDataBlock({libelle, value}: SimpleDataBlockProps) {


    return (
       <div className="SimpleDataBlock">
            <div>{libelle}</div>
            <div>{value}</div>
       </div>
    )
}

export function SingleDataBlock({value, unit=""}: SingleDataBlockProps) {


    return (
       <div className="SingleDataBlock">
            {value + unit}
       </div>
    )
}

export function SmallDataBlock({libelle, valueN, valueN_1, unit=""}: DataBlockProps) {

    let pct = getPourcentageEvolution(valueN, valueN_1);

    return (
       <div className="SmallDataBlock">
            <div>{libelle}</div>
            <div>
                <div className='values'>
                    <div>{valueN+unit}</div>
                    <div>{valueN_1+unit}</div>
                </div>
                <div className={'pct' + (pct >= 0 ? " up" : " down")}>
                    {pct > 0 ? <TrendUpIcon/> : <TrendDownIcon/>}
                    {(pct>0 ? "+":"") + pct.toFixed(1)+"%"}
                </div>
            </div>
       </div>
    )
}

export function SmallDataBlockContainer({children}: SmallDataBlockContainerProps) {


    return (
       <div className="SmallDataBlockContainer">
            {children}
       </div>
    )
}

function getPourcentageEvolution (valueN : number, valueN_1 : number) : number{
    if (valueN_1 == null || valueN_1 === 0) {
        if (valueN === 0) return 0;
        return 100;
    }

    return 100*(valueN-valueN_1)/valueN_1;
   
}


export default function Meteo({meteoN, meteoN_1}: MeteoBlockProps) {

    const isIdMeteoValid = (id: number) => {
        return id >= 0 && id <=10
    }

    return (
       <div className="Meteo">
            <img className={isIdMeteoValid(meteoN) ? "" : " hidden"} src={'/meteo_'+meteoN+'.png'}/>
            <img className={"meteoN_1" + (isIdMeteoValid(meteoN_1) ? "" : " hidden")} src={'/meteo_'+meteoN_1+'.png'}/>
       </div>
    )
}
