import { useEffect, useState } from 'react';
import WidgetHeader from '../WidgetHeader';
import WidgetSlider from '../WidgetSlider';
import { CaisseLocationData } from "../../models/shopData";
import Meteo, { SmallDataBlock, SmallDataBlockContainer } from '../DataBlock';
import dayjs from 'dayjs';
import { getDateAsString, getDateRange, getRefresh } from '../../utils/Widgets';
import { DashboardWidget, DashboardWidgetSlide } from '../../models/widget';
import { getCaisseLocation } from '../../api/caisseApi';
import WidgetSearchFrame from '../WidgetSearchFrame';
import useWindowDimensions from '../../app/hooks';
import { smallWidth } from '../../utils/Const';
import { WidgetContent } from '../WidgetContent';

interface CaisseVenteProps {
    shopId: number,
    widget: DashboardWidget
}

interface CaisseLocationSlideProps {
    shopId: number;
    slide: DashboardWidgetSlide
    widget: DashboardWidget
}


export default function CaisseLocation({shopId, widget} : CaisseVenteProps) {

    let [slides, setSlides] = useState<DashboardWidgetSlide[]>(getDateRange(new Date().toISOString(), 4, 4).map((el) => {return {debut: el, fin: el, timestamp: null}}));
    let [fixedSlide, setFixedSlide] = useState<DashboardWidgetSlide>({debut: '@EXERCICEDEBUT@', fin: dayjs().format('YYYY-MM-DD'), timestamp: null});

    const height = (useWindowDimensions().width < smallWidth ? 295 : 320 ) + 'px';

    return (
       <div className="Widget" style={{height:height}}>
            <WidgetHeader title="Caisse de location" widget={widget} withFixedFrame={true}/>
            {widget.search ? 
                <WidgetSearchFrame widget={widget} withDateFin={true}>
                    {widget.searchDateDebut && widget.searchDateFin ?
                        <CaisseLocationSlide 
                            widget={widget}
                            shopId={shopId} 
                            slide={{debut: widget.searchDateDebut, fin: widget.searchDateFin, timestamp: ""}} /> 
                        : null
                    }   
                </WidgetSearchFrame>
            :  
                ( widget.fixedFrame ? 
                    <CaisseLocationSlide 
                        widget={widget}
                        shopId={shopId} 
                        slide={fixedSlide} />
                    :
                    <WidgetSlider widget={widget} defaultIndex={slides.map(el => el.debut).indexOf(dayjs().format('YYYY-MM-DD'))}>
                        {slides.map(s => {
                            return (
                            <CaisseLocationSlide 
                                widget={widget}
                                shopId={shopId} 
                                slide={s} 
                                key={s.debut} />)
                        })}
                    </WidgetSlider>
                )
            }
       </div>
    )
}


function CaisseLocationSlide ({shopId, slide, widget} : CaisseLocationSlideProps) {

    const [data, setData] = useState<CaisseLocationData | null>(null);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        setData(null);
        setError(false);
        getCaisseLocation(shopId,slide.debut, slide.fin, getRefresh(slide.timestamp, widget.refreshTime))
        .then((res) => {
            setData(res);
            slide.timestamp = res.timestamp;
        })
        .catch((err) => {
            console.log(err);
            setError(true);
        });


        
    }, [widget.refreshTime, slide.debut, slide.fin]);

    return (
        <WidgetContent 
        data={data} 
        widget={widget} 
        error={error} 
        date={slide.debut === "@EXERCICEDEBUT@" ? "Exercice complet": getDateAsString(slide.debut, slide.fin)}>
            {data == null ? 
                null :
                <div>
                    <Meteo meteoN={data.idMeteoN} meteoN_1={data.idMeteoN_1}/>
                    <SmallDataBlockContainer>
                        <SmallDataBlock
                            unit={String.fromCodePoint(8364)}
                            valueN={data.panierLocMoyN}
                            valueN_1={data.panierLocMoyN_1}
                            libelle="Montant du panier moyen"/>
                        <SmallDataBlock
                            unit={String.fromCodePoint(8364)}
                            valueN={data.CASupplementN}
                            valueN_1={data.CASupplementN_1}
                            libelle="Montant des suppléments"/>
                        <SmallDataBlock
                            unit={String.fromCodePoint(8364)}
                            valueN={data.MontantLocN}
                            valueN_1={data.MontantLocN_1}
                            libelle="Montant total"/>
                        <SmallDataBlock
                            unit={String.fromCodePoint(8364)}
                            valueN={data.CAGarantieN}
                            valueN_1={data.CAGarantieN_1}
                            libelle="Montant des garanties"/>
                    </SmallDataBlockContainer>
                </div>
            }
        </WidgetContent>
            
    )
}