import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SmallLoader } from "./Loaders";
import { StandardButton } from "./Buttons";

import '../styles/dialog.css'

interface DialogProps {
    children: React.ReactNode,
    confirmText: string,
    confirmCallback?: () => void,
    cancelText: string,
    cancelCallback?: () => void,
    loading: boolean,
    setLoading: Dispatch<SetStateAction<boolean>>

}


export function Dialog ({ children, confirmText, confirmCallback, cancelText, cancelCallback, loading, setLoading }: DialogProps){

    const [open, setOpen] = useState(false);

    const onClickConfirm = async () =>{
        setLoading(true);
        if (confirmCallback && typeof confirmCallback === "function") {
            confirmCallback();
        }
    }

    const onClickCancel = async () =>{
        setLoading(true);
        if (cancelCallback && typeof cancelCallback === "function") {
            cancelCallback();
        }
    }
    
    useEffect(() => {
        setOpen(true);

        return () => {
        setOpen(false);
        };
    }, []);
    
    return (
        <div className='modal-container'>
            <div className={"modal "+ (open ? "expand": "")}>
                <div className="to">
                    <div className="to-contents">
                        {loading ? 
                            <div className="modal-body">
                                <SmallLoader/>
                            </div> :
                            <div className="modal-body">
                                {children}
                            </div>
                        }
                        <div className="modal-footer">
                            <StandardButton  label={confirmText} onClick={onClickConfirm} disabled={loading}></StandardButton>
                            <StandardButton label={cancelText} onClick={onClickCancel} disabled={loading}></StandardButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}