import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Error from "./routes/Error";
import Home from "./routes/Home";
import Login from "./routes/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./routes/Dashboard";
import { Settings } from "./routes/Settings";
import { ShopGroup } from "./routes/ShopGroup";

export default function App() {

  const router = createBrowserRouter([
    {
      path:'/',
      errorElement: <Error/>,
      // element: <Container/>,
        children: [
        {
          path: "/",
          element: <ProtectedRoute/>,
          // element: <ErrorBoundary fallback={<Error/>}><ProtectedRoute allowedRoles={['user']}/></ErrorBoundary>,
          children:[
            {
              path:"/",
              element: <Home/>
            },
            {
              path:"/:type/:shopId",
              element: <Dashboard/>
            },
            {
              path:"/parametres",
              element: <Settings/>
            },
            {
              path:"/parametres/groupe/:groupId",
              element: <ShopGroup/>
            },
          ],
        },
        {
          path: "/login",
          element: <Login/>,
        }
      ]
    }
  ]);

  return <RouterProvider router={router} />
}