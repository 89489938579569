import dayjs from "dayjs";


export function getDateRange (startDate: string, before:number, after: number) :string [] {

    let result :string[] = [];
    for (var i = -1*before; i<=after; i++) {
        result.push(dayjs(startDate).add(i, 'day').format('YYYY-MM-DD'))
    }

    return result;

}

export function getDateAsString (debut: string | null, fin: string | null, short:boolean = false) :string {

    if (!debut || ! fin) return ""

    if (debut === fin) return dayjs(debut).format('dddd D MMMM') 
          
    if (short) return "du "+ dayjs(debut).format('dddd DD/MM') + " au "+ dayjs(fin).format('dddd DD/MM');
    return "du "+ dayjs(debut).format('dddd D MMMM') + " au "+ dayjs(fin).format('dddd D MMMM');

                        
}

export function getRefresh (timestamp: string | null, refreshTime: string | null) :boolean {

    let refresh: boolean = false;
    if (timestamp == "" || timestamp && refreshTime != null && refreshTime > timestamp) refresh = true;
          
    return refresh;
}