import { useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from './store'
import { useState, useEffect } from 'react';

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function getWindowTheme() {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light";

}

export function useWindowTheme() {
  const [windowTheme, setWindowTheme] = useState(getWindowTheme());

  useEffect(() => {
    function handleChangeTheme() {
      setWindowTheme(getWindowTheme());
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handleChangeTheme);
    return () =>  window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', handleChangeTheme);
  }, []);

  return windowTheme;
}
