import { EChartOption } from "echarts";
import { ReactECharts } from "./ReactECharts";
import { formatLabels } from "../../utils/Charts";
import { CSSProperties } from "react";
import ChartData from "../../models/chartData";
import useWindowDimensions from "../../app/hooks";
import { smallWidth } from "../../utils/Const";

interface PolarChartProps {
    data: ChartData[];
    theme: string;
    style?: CSSProperties;
}



export function PolarChart ({data, theme, style} : PolarChartProps){

    const {width} = useWindowDimensions();
    const fontSize = width < smallWidth ? 10 : 12;
    const centerY =  width < smallWidth ? '43%' : '45%';
    const colors = getPolarChartColorTheme(theme);

    style = {
        ...style,
        width:'96%'
    }

    let textColor = '#5c5858'; //TODO : global

    let labels = formatLabels(data.map(el => el.name), 15);
    
  
    let options: EChartOption = {
        grid: {
            left: 10,
            top: 0,
            right:10,
            bottom: 0,
            containLabel :true
        },
        legend: {
            bottom: 0,
            data: labels,
            textStyle:{
                fontSize : fontSize,
                color : textColor
            }
        },
        color: colors,
        polar: {
            radius: [30, '75%'],
            center :['50%', centerY]
            // radius: [30, '75%'],
            // center :['50%','45%']
        },
        angleAxis: {
            startAngle: 75,
            max: Math.max(...data.map(el => el.valueN))*1.1,
            axisLabel:{
                fontSize : fontSize,
            }
        },
        radiusAxis: {
            show: false,
            type: 'category',
        },
        tooltip: {formatter: '{a} <br><b>{c}</b>'},
        series: data.map((el, index) => {

            let formattedData =  Array(data.length);
            formattedData[index] = el.valueN;

            return {
                name: labels[index],
                type:'bar',
                stack: 'stack',
                coordinateSystem: 'polar',
                data:formattedData,
                label: {
                    color: textColor, //TODO : ajuster en fonction de la couleur du bg
                    show: true,
                    position: 'middle',
                    formatter: '{c}',
                    fontSize : fontSize
                  }
            }
            
        }),
    }
  
    return  (
        <ReactECharts option={options} style={style}/> 
    )
  
}

const getPolarChartColorTheme = (color: string) : string[] => {
    switch(color){
        case "purple" :
            return ['#d1addc', '#c276e1', '#c2bbfc', '#79429c', '#5d4fd1', '#7e13c9', '#926cc8', '#55309f', '#547ae7', '#9eb2e6'];
        case "orange" : 
            return ['#dae6b1', '#f8ff58', '#ffc31c', '#ffde00', '#bf9c4b', '#ebc8b6', '#f2c58e', '#e8c90a', '#faa788', '#f3b2aa'];
        case "green" : 
            return ['#e3f5e3', '#8cdb86', '#b4efaa', '#62c54c', '#e1d39b', '#efffa2', '#93d065', '#b7ff47', '#b2da00', '#e8e863'];
        default : //bleu
            return ['#c7d4e7', '#d1fafc', '#94a7c8', '#d1e8fb', '#26a9de', '#96bdcb', '#2da7ca', '#8ddae9', '#64d3f9', '#a6e8e2'];
    }
}