
import { addWidgetToFavorites, removeWidgetFromFavorites } from '../api/widgetsApi';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { duplicateWidget, removeDuplicatedWidget, setFixedFrame, setRefreshTime, setSearch } from '../features/DashboardSlice';
import { updateWidgets } from '../features/UserSlice';
import { DashboardWidget, Widget } from '../models/widget';
import '../styles/widgetHeader.css';
import { CalendarIcon, DuplicateIcon, HeartIcon, RefreshIcon, SearchIcon, SwiperIcon, XIcon } from './Icons';

interface WidgetHeaderProps {
    title: string;
    widget: DashboardWidget;
    searchable?: boolean;
    duplicable?: boolean;
    withFixedFrame?: boolean;

}

export default function WidgetHeader({title, widget, searchable=true, duplicable=true, withFixedFrame=false}: WidgetHeaderProps) {

    const dispatch = useAppDispatch();
    const favorite: boolean = useAppSelector((state) => state.user.widgets).find ((el:Widget) => el.id === widget.id && el.favorite) != null;

    const onClickFavoriteButton = () => {
        if (!favorite) {
            addWidgetToFavorites(widget.id, widget.slideIndex || null)
            .then((res) => {
                dispatch(updateWidgets(res));
            })
            .catch((err) => {
                console.log(err); //TODO : gestion d'erreur
            });
        }
        else {
            removeWidgetFromFavorites(widget.id)
            .then((res) => {
                dispatch(updateWidgets(res));
            })
            .catch((err) => {
                console.log(err); //TODO : gestion d'erreur
            });

        }
    }

    const onClickDuplicateButton = () => {
        dispatch(duplicateWidget(widget));
    }

    const onClickRemoveButton = () => {
        dispatch(removeDuplicatedWidget(widget));
    }

    const onClickSearch = () => {
        dispatch(setSearch({id: widget.id, occurence: widget.occurence, search:true}))
    }

    const onClickFixedFrame = () => {
        dispatch(setFixedFrame({id: widget.id, occurence: widget.occurence, fixedFrame:!widget.fixedFrame}))
    }

    const onClickRefresh = () => {
        dispatch(setRefreshTime({id: widget.id, occurence: widget.occurence, refreshTime:new Date().toISOString()}));
    }

    

    return (
       <div className="WidgetHeader">
            <h2>{title}</h2>
            <div>
                <button onClick={onClickRefresh}><RefreshIcon/></button>
                {widget.occurence === 0 && duplicable ? <button onClick={onClickDuplicateButton}><DuplicateIcon/></button> : null}
                {searchable ? <button onClick={onClickSearch}><SearchIcon/></button> : null}
                {withFixedFrame ? 
                    <button onClick={onClickFixedFrame}>
                        {widget.fixedFrame ? <SwiperIcon/> : <CalendarIcon/>}
                    </button> 
                : null}
                {widget.occurence === 0 ? 
                    <button className={favorite ? "": "notSelected"} onClick={onClickFavoriteButton}><HeartIcon/></button>:
                    <button onClick={onClickRemoveButton}><XIcon/></button>
                }
                
            </div>
       </div>
    )
}