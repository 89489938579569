
import { Masonry } from '@mui/lab';
import '../styles/widgetContainer.css';

interface WidgetContainerProps {
    children: NonNullable<React.ReactNode>;
}

export default function WidgetContainer({children}: WidgetContainerProps) {

 
    return (
       <div className="WidgetContainer">
            <Masonry columns={{ sm: 1, md: 2, xl: 3 }} spacing={2} sx={{ width: "100%" }}>
                {children}
            </Masonry>
       </div>
    )
}