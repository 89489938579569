import { useNavigate } from "react-router-dom";
import { MainHeader } from "../components/Headers";
import { BackIcon, ChevronIcon, EditIcon, GroupIcon, PlusIcon, ShopIcon, XIcon } from "../components/Icons";

import '../styles/settings.css'
import { Dispatch, SetStateAction, useState } from "react";
import { Group } from "../models/group";
import { useAppSelector } from "../app/hooks";
import { Shop } from "../models/shop";
import { Dialog } from "../components/Dialog";
import { deleteGroup } from "../api/groupApi";
import { useDispatch } from "react-redux";
import { updateGroups } from "../features/UserSlice";

interface GroupRowProps {
    group:Group,
    shops: Shop[],
}

export function Settings () {

    const navigate = useNavigate();

    return (
        <div className="Settings">
            <MainHeader title={"Paramètres"}>
            <button onClick={() => navigate('/')}><BackIcon/></button>
            </MainHeader>

            <GroupsSettings/>

        </div>
    )
}

function GroupsSettings () {

    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(true);
    const groups: Group[] = useAppSelector((state) => state.user.groups);
    const shops: Shop[] = useAppSelector((state) => state.user.shops);

    return (
        <div className={"settingsBlock GroupSettings" + (open? "" : " hidden")}>
            <div className="blockHeader" onClick={() => setOpen(!open)}>
                <GroupIcon/>
                <span>Mes groupes</span>
                <button><ChevronIcon/></button>
            </div>
            <div className="content">
                {
                    groups.map(g => {
                        return (
                            <GroupRow group={g} shops={shops} key={g.id}/>
                        )
                    })
                }
                <button onClick={() => navigate('groupe/0')}><PlusIcon/> Créer un groupe</button>
            </div>
        </div>
    )
}

function GroupRow ({group, shops} : GroupRowProps) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const onClickDelete = () => {
        deleteGroup(group.id)
        .then((res) => {
            console.log(res);
            dispatch(updateGroups(res))
            navigate('/parametres');
        })
        .catch((e) => console.log(e))
    }

    return (
        <div className="GroupRow">
            <span className="group">{group.name}</span>
            <div className="buttons">
                <button><EditIcon onClick={() => navigate('groupe/'+group.id)}/></button>
                <button onClick={() => setModalOpen(true)}><XIcon/></button>
            </div>
            <div className="shops">
                <ShopIcon/>
                <div>
                    {shops
                        .filter(s => group.shops?.includes(s.id))
                        .reduce((str,shop) => {return str+", "+shop.name}, '')
                        .slice(1)
                    }
                </div>
            </div>
            {modalOpen ? 
                <Dialog
                    confirmText="OK"
                    confirmCallback={onClickDelete}
                    cancelText="Retour"
                    cancelCallback={() => setModalOpen(false)}
                    loading={false}
                    setLoading={() => {}}>
                    <span>Supprimer le groupe ?</span>
                </Dialog>
                : null
            }
        </div>
    )
}