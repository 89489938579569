
import '../styles/buttons.css';

interface ButtonProps {
    label: string;
    disabled:boolean
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export function StandardButton({label, onClick, disabled} : ButtonProps) {
    return (
        <button 
            disabled={disabled}
            className="StandardButton"
            onClick={onClick}>
            {label}
        </button>
    )
}