import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation, Pagination, A11y } from 'swiper/modules';
import 'swiper/css/bundle';

import '../styles/widgetSlider.css';
import { CSSProperties, useRef } from "react";
import { DashboardWidget } from "../models/widget";
import { useAppDispatch } from "../app/hooks";
import { setCurrentSlide } from "../features/DashboardSlice";

interface WidgetSliderProps {
    style?:CSSProperties;
    defaultIndex: number;
    widget: DashboardWidget
    children: React.ReactNode[];
}

export default function WidgetSlider({children, defaultIndex, style, widget} : WidgetSliderProps) {

    const sliderRef = useRef(null);
    const dispatch = useAppDispatch();

    const onSlideChange = (swiper: any) => {
        dispatch(setCurrentSlide({id:widget.id, occurence:widget.occurence, slideIndex: swiper.activeIndex}));
    }

    return (
       <div className="WidgetSlider">
        <Swiper
            onSlideChange={onSlideChange}
            initialSlide={widget.slideIndex || defaultIndex}
            ref={sliderRef}
            spaceBetween={50}
            slidesPerView={1}
            modules={[Navigation, Pagination, A11y]}
            navigation
            pagination={{ clickable: false }}
        >
            {
                children.map((slide, index) => {
                    return  <SwiperSlide key={index} style={{...style}}>
                        {({ isActive }) => (
                            isActive ? slide : null
                        )}
                    </SwiperSlide>
                })
            }
        </Swiper>           
       </div>
    )
}