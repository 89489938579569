import WidgetHeader from '../WidgetHeader';
import { SmallDataBlock, SmallDataBlockContainer } from '../DataBlock';
import { DashboardWidget, DashboardWidgetSlide } from '../../models/widget';
import WidgetSearchFrame from '../WidgetSearchFrame';
import { useEffect, useState } from 'react';
import { CAGeneralJJ7J30ExData } from '../../models/shopData';
import { getCAGeneralJJ7J30Ex } from '../../api/caisseApi';
import dayjs from 'dayjs';
import useWindowDimensions from '../../app/hooks';
import { smallWidth } from '../../utils/Const';
import { WidgetContent } from '../WidgetContent';
import { getDateAsString, getRefresh } from '../../utils/Widgets';

interface ChiffreAffairesProps {
    shopId: number,
    widget: DashboardWidget
}

interface ChiffreAffairesSlideProps {
    shopId: number,
    slide: DashboardWidgetSlide
    widget: DashboardWidget
}

export default function ChiffreAffaires({shopId, widget} : ChiffreAffairesProps) {

    const [slide, setSlide] = useState<DashboardWidgetSlide>({debut:dayjs().format('YYYY-MM-DD'), fin:dayjs().format('YYYY-MM-DD'), timestamp:null})

    const height = (useWindowDimensions().width < smallWidth ? 265 : 290 ) + 'px';

    return (
        <div className="Widget" style = {{height:height}}>
            <WidgetHeader title="Chiffre d'affaires" widget={widget}/>
            {widget.search ? 
                    <WidgetSearchFrame widget={widget} withDateFin={false}>
                        {widget.searchDateDebut ?
                            <ChiffreAffairesSlide 
                                widget={widget}
                                shopId={shopId} 
                                slide={{debut: widget.searchDateDebut, fin: widget.searchDateDebut, timestamp: ""}} /> 
                            : null
                        }   
                    </WidgetSearchFrame>
            :  

            <ChiffreAffairesSlide 
                widget={widget}
                shopId={shopId} 
                slide={slide} 
                key={slide.debut} />           
            }
       </div>
    )
}



function ChiffreAffairesSlide ({shopId, slide, widget} : ChiffreAffairesSlideProps) {

    const [data, setData] = useState<CAGeneralJJ7J30ExData | null>(null);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        setData(null);
        setError(false);
        getCAGeneralJJ7J30Ex(shopId, slide.debut, getRefresh(slide.timestamp, widget.refreshTime))
        .then((res) => {
            setData(res);
            slide.timestamp = res.timestamp
        })
        .catch((err) => {
            console.log(err);
            setError(true);
        });
        
    }, [widget.refreshTime, slide.debut, slide.fin]);

    return (
        <WidgetContent 
        style={{height:(useWindowDimensions().width < smallWidth ? 180 : 200 ) + 'px'}}
        data={data} 
        widget={widget} 
        error={error} 
        date={getDateAsString(slide.debut, slide.debut)}>       
            {data == null ? 
                null :
                <SmallDataBlockContainer>
                    <SmallDataBlock
                    unit={String.fromCodePoint(8364)}
                    valueN={data.chiffreAffairesJN}
                    valueN_1={data.chiffreAffairesJN}
                    libelle="jour J"/>
                    <SmallDataBlock
                    unit={String.fromCodePoint(8364)}
                    valueN={data.chiffreAffairesJ7N}
                    valueN_1={data.chiffreAffairesJ7N_1}
                    libelle="J-7"/>
                    <SmallDataBlock
                    unit={String.fromCodePoint(8364)}
                    valueN={data.chiffreAffairesJ30N}
                    valueN_1={data.chiffreAffairesJ30N_1}
                    libelle="J-30"/>
                    <SmallDataBlock
                    unit={String.fromCodePoint(8364)}
                    valueN={data.chiffreAffairesExN}
                    valueN_1={data.chiffreAffairesExN_1}
                    libelle="Exercice"/>
                </SmallDataBlockContainer>
            }
        </WidgetContent>
    )
}

