import { CAGeneralData, CAGeneralJJ7J30ExData, CaisseLocationData, CaisseVenteData, PrixMoyCategorieData } from "../models/shopData";

export async function getCAGeneralJJ7J30Ex (shopId: number|string, date:string, refresh: boolean): Promise<CAGeneralJJ7J30ExData> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/caisse/general/'+shopId+'/'+(refresh? "1" : "0")+'/'+date, {
        method: 'GET',
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }


    let json = await response.json();
    return json;
}

export async function getCaisseVente (shopId: number|string, debut:string, fin:string, refresh: boolean): Promise<CaisseVenteData> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/caisse/vente/'+shopId+'/'+(refresh? "1" : "0")+'/'+debut+'/'+fin, {
        method: 'GET',
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }


    let json = await response.json();
    return json;
}

export async function getCaisseLocation (shopId: number|string, debut:string, fin:string, refresh: boolean): Promise<CaisseLocationData> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/caisse/location/'+shopId+'/'+(refresh? "1" : "0")+'/'+debut+'/'+fin, {
        method: 'GET',
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }


    let json = await response.json();
    return json;
}


export async function getCAEvolution (shopId: number|string, debut:string, fin:string, refresh: boolean, step: number): Promise<CAGeneralData []> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/caisse/ca/'+shopId+'/'+(refresh? "1" : "0")+'/'+debut+'/'+fin+'/'+step, {
        method: 'GET',
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }


    let json = await response.json();
    return json;
}

export async function getPrixMoyCategorie (shopId: number|string, debut:string, fin:string, refresh: boolean, top:number): Promise<PrixMoyCategorieData> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/caisse/prix/'+shopId+'/'+(refresh? "1" : "0")+'/'+debut+'/'+fin+'/'+top, {
        method: 'GET',
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }


    let json = await response.json();
    return json;
}