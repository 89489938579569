import { ClickAwayListener, Tooltip } from "@mui/material";
import { useState } from "react";

interface ErrorTooltipProps {
    children: React.ReactElement;
    title: string;
    placement: "bottom" | "left" | "right" | "top" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start" | undefined;
}

export function ErrorTooltip ({children, title, placement} :ErrorTooltipProps) {

    const [open, setOpen] = useState<boolean>(false);

    let componentsProps={
        tooltip: {
          sx: {
            backgroundColor: 'var(--bg-red)',
            color: 'var(--text-red)',
            fontSize:'13px',
            fontWeight: 600
          }
        },
        arrow: {
            sx: {
              color: 'var(--bg-red)'
            }
          }
    }

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div>
                <Tooltip 
                    title={title} 
                    sx={{fontSize:30}}
                    componentsProps={componentsProps}
                    open={open}
                    arrow={true}
                    placement={placement}
                    disableHoverListener={false} 
                    disableTouchListener={false} 
                    disableFocusListener={true} 
                    >
                    <button onClick={() => setOpen(true)}>{children}</button>
                </Tooltip>
            </div>
          </ClickAwayListener>
        
    )
}

// export function InfoTooltip ({children, title, placement, disabled=false}) {

//   let componentsProps={
//       tooltip: {
//         sx: {
//           backgroundColor: 'var(--info-bg-color)',
//           color: 'var(--text-color)',
//           fontSize:'13px',
//           fontWeight: 600
//         }
//       },
//       arrow: {
//           sx: {
//             color: 'var(--info-bg-color)'
//           }
//         }
//   }

//   return (
//       <Tooltip 
//           title={title} 
//           sx={{fontSize:30}}
//           componentsProps={componentsProps}
//           arrow 
//           disableHoverListener={disabled} 
//           disableTouchListener={true} 
//           disableFocusListener={true} 
//           placement={placement}>
//           {children}
//       </Tooltip>
//   )
// }