import { PerformancesData, PrevisionsRetoursData, PrevisionsSortiesData } from "../models/shopData";

export async function getPerformancesParCategories (shopId: number|string, date:string, top:number, refresh: boolean): Promise<PerformancesData> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/location/performances/'+shopId+'/'+(refresh? "1" : "0")+'/'+date+'/'+top, {
        method: 'GET',
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }


    let json = await response.json();
    return json;
}

export async function getPrevisionsSorties (shopId: number|string, date:string, top:number, refresh: boolean): Promise<PrevisionsSortiesData> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/location/sorties/'+shopId+'/'+(refresh? "1" : "0")+'/'+date+'/'+top, {
        method: 'GET',
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }


    let json = await response.json();
    return json;
}

export async function getPrevisionsRetours (shopId: number|string, date:string, top:number, refresh: boolean): Promise<PrevisionsRetoursData> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/location/retours/'+shopId+'/'+(refresh? "1" : "0")+'/'+date+'/'+top, {
        method: 'GET',
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }


    let json = await response.json();
    return json;
}