import { CSSProperties } from "react";

import '../styles/widgetSearchFrame.css'
import { DateInput } from "./Inputs";
import dayjs, { Dayjs } from "dayjs";
import { XIcon } from "./Icons";
import { DashboardWidget } from "../models/widget";
import { useAppDispatch } from "../app/hooks";
import { setSearch, setSearchDateDebut, setSearchDateFin } from "../features/DashboardSlice";

interface WidgetSearchFrameProps {
    withDateFin: boolean;
    widget: DashboardWidget;
    style?:CSSProperties;
    children: React.ReactNode;
}

export default function WidgetSearchFrame ({widget, style,children, withDateFin}: WidgetSearchFrameProps) {

    const dispatch = useAppDispatch();

    const closeSearchFrame = () => {
        dispatch(setSearchDateDebut({id:widget.id, occurence:widget.occurence, date:null}));
        if (withDateFin) dispatch(setSearchDateFin({id:widget.id, occurence:widget.occurence, date:null}));
        dispatch(setSearch({id:widget.id, occurence:widget.occurence, search:false}));
    }

    const onChangeDateDebut = (val: Dayjs | null) => {
        dispatch(setSearchDateDebut({id:widget.id, occurence:widget.occurence, date:val?.format('YYYY-MM-DD') || null}))
    }

    const onChangeDateFin = (val: Dayjs | null) => {
        dispatch(setSearchDateFin({id:widget.id, occurence:widget.occurence, date:val?.format('YYYY-MM-DD') || null}))
    }

    return (
      <div style={style} className="WidgetSearchFrame">
            <div className="search">
                {withDateFin ? <span>du : </span> : null}
                <DateInput value={dayjs(widget.searchDateDebut)} name="" onChange={onChangeDateDebut}/>
                {withDateFin ? <span>au : </span> : null}
                {withDateFin ? <DateInput value={dayjs(widget.searchDateFin)} name="" onChange={onChangeDateFin}/> : null}
                <button onClick={closeSearchFrame}><XIcon/></button>
            </div>
            {children}
      </div>
    );

}
  