import { useNavigate } from "react-router-dom";
import { logout } from "../api/authApi";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { unsetUser } from "../features/UserSlice";
import { MainHeader } from "../components/Headers";
import { ChevronIcon, GroupIcon, LogoutIcon, RefreshIcon, SettingsIcon, ShopIcon } from "../components/Icons";

import '../styles/home.css';
import { useEffect, useState } from "react";
import { getCAGeneralJJ7J30Ex } from "../api/caisseApi";
import dayjs from "dayjs";
import { CAGeneralJJ7J30ExData } from "../models/shopData";
import { SmallLoader } from "../components/Loaders";
import { User } from "../models/user";
import { getGroupColorTheme } from "../utils/Shops";

interface ShopRowProps {
  id:number,
  name: string
}

interface ShopBlockProps {
    title: string,
    children: React.ReactNode[],
    icon: React.ReactNode
  }

export default function Home() {

  const dispatch = useAppDispatch();
  const shops = useAppSelector((state) => state.user.shops);
  const groups = useAppSelector((state) => state.user.groups);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout()
    .then((res) => {
      dispatch(unsetUser())
    });
  }

  return (
    <div>
        <MainHeader title="Accueil">
            <button onClick={() => navigate('/parametres')}><SettingsIcon/></button>
            <button onClick={handleLogout}><LogoutIcon/></button>
        </MainHeader>
        <div className="ShopRow header">
            <div></div>
            <div></div>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td>J</td>
                            <td>J-7</td>
                            <td>J-30</td>
                            <td>Exercice</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <ShopBlock title="Mes groupes" icon={<GroupIcon/>}>

            <ShopRow id={0} name={"Général"}/>
            {
            groups.map(s => {
                return <ShopRow id={-1*s.id} name={s.name} key={-1*s.id}/>
            })
            }
        </ShopBlock>
        <ShopBlock title="Mes magasins" icon={<ShopIcon/>}>
            {
                [...shops]
                .sort((a,b) => a.name > b.name ? 1 : -1)
                .map(s => {
                return <ShopRow id={s.id} name={s.name} key={s.id}/>
                })
            }
        </ShopBlock>
    </div>
  );
}


function ShopBlock ({title, children, icon} :ShopBlockProps) {

    const [display, setDisplay] = useState<boolean>(true);

    return (
        <div className={"ShopBlock" + (display? "" : " hidden")} onClick={() => setDisplay(!display)}>
            <div className="blockHeader">
                {icon}
                <span>{title}</span>
                <button><ChevronIcon/></button>
            </div>
            <div className="content">
                <div>
                    {children}
                </div>
            </div>
        </div>
    )

}

function ShopRow ({id, name}: ShopRowProps) {

    const [data, setData] = useState<CAGeneralJJ7J30ExData | null>(null);
    const [error, setError] = useState<boolean>(false);
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState<boolean>(false);

    const user: User = useAppSelector((state) => state.user);

    const color: string | null = id < 0 ? user.groups.find(el => el.id === -id)?.color || null : null ;
    
    useEffect(() => {
        if (data == null) {
            getCAGeneralJJ7J30Ex(id, dayjs().format('YYYY-MM-DD'), refresh)
            .then((res) => setData(res))
            .catch((err) => {
                console.log(err);
                setError(true);
            });
            setRefresh(false);
        }
    }, [data]);

    return (
        <div className={"ShopRow" + (data == null || data?.date == "" ? " disabled": "")} style={getGroupColorTheme(color)} onClick={() => navigate('/favoris/'+id)}>
            <div>
                {data != null ?
                    <div className={"bulle " + (data.date == "" ? "red" : (data.shopsKO.length > 0 ? ' orange': 'green'))}></div>
                    : <SmallLoader/>
                }
            </div>
            <div>
                <span>{name}</span>
            </div>
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td>{data ? data.chiffreAffairesJN + String.fromCodePoint(8364) : "-"}</td>
                            <td>{data ? data.chiffreAffairesJ7N + String.fromCodePoint(8364) : "-"}</td>
                            <td>{data ? data.chiffreAffairesJ30N + String.fromCodePoint(8364) : "-"}</td>
                            <td>{data ? data.chiffreAffairesExN + String.fromCodePoint(8364) : "-"}</td>
                        </tr>
                        <tr>
                            <td>{data && data.chiffreAffairesJN_1 > 0 ? data.chiffreAffairesJN_1 + String.fromCodePoint(8364) : "-"}</td>
                            <td>{data && data.chiffreAffairesJ7N_1 > 0 ? data.chiffreAffairesJ7N_1 + String.fromCodePoint(8364) : "-"}</td>
                            <td>{data && data.chiffreAffairesJ30N_1 > 0 ? data.chiffreAffairesJ30N_1 + String.fromCodePoint(8364) : "-"}</td>
                            <td>{data && data.chiffreAffairesExN_1 > 0 ? data.chiffreAffairesExN_1 + String.fromCodePoint(8364) : "-"}</td>
                        </tr>
                    </tbody>
                </table> 
            </div>
            <div className="overlay" onClick={(e) => {e.stopPropagation();setRefresh(true); setData(null)}}>
                <button><RefreshIcon/></button>
            </div>
        </div>
    )
}