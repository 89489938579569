
import { useState } from 'react';
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { frFR } from '@mui/x-date-pickers/locales';
import { HideIcon, ShowIcon } from './Icons';

import '../styles/inputs.css';
import { Dayjs } from 'dayjs';

interface InputProps {
    name: string;
    label: string;
    value: string;
    icon?: React.ReactNode;
    className?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>void;
}

interface DateInputProps {
    name: string;
    value: Dayjs | null;
    minDate?: Dayjs;
    maxDate?: Dayjs;
    onChange: (e: Dayjs | null)=>void;
}

interface ToggleSwitchProps {
    name: string;
    label: string;
    isChecked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>)=>void;
}

export function TextInput({label, name, value, onChange, icon, className} : InputProps) {
    return (
        <div className={"input " + (className ? className : "")}>
            {icon}
            <input 
                type="text"
                placeholder={label}
                name={name} 
                value={value} 
                onChange={onChange}/>
        </div>
    )
}

export function PasswordInput({label, name, value, onChange, icon, className} : InputProps) {

    const [showPassword, setShowPassword] = useState<boolean> (false);

    return (
        <div className="input">
            {icon}
            <input 
                type = {showPassword ? 'text' : 'password'}
                placeholder={label}
                name={name} 
                value={value} 
                onChange={onChange}/>
            <button onClick={() => setShowPassword(!showPassword)} type='button' tabIndex={-1}>
                {showPassword ? <ShowIcon/> : <HideIcon/>}
            </button>
        </div>
    )
}

export function DateInput({name, value, onChange, minDate, maxDate} : DateInputProps) {

    return (
        <LocalizationProvider
            localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
            <DatePicker
                maxDate={maxDate}
                minDate={minDate}
                className="DateInput"
                value={value} 
                name={name}
                onChange={(val) => onChange(val)} 
            />
        </LocalizationProvider>
    )
}

export function ToggleSwitch({label, name, isChecked, onChange}: ToggleSwitchProps){
    return (
        <div className="switchContainer">
            <label className="switch">
                <input type="checkbox" name={name} checked={isChecked} onChange={onChange}/>
                <span className="slider round"></span>
            </label>
            <label className="switchLabel">{label}</label>
        </div>
    )
}