import { CAGeneralJJ7J30ExData, PalmaresData } from "../models/shopData";

export async function getPalmares (shopId: number, type:string, of:string, debut:string, fin:string, top:number, refresh: boolean): Promise<PalmaresData> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/palmares/'+type+'/'+of+'/'+shopId+'/'+(refresh? "1" : "0")+'/'+debut+'/'+fin+'/'+top, {
        method: 'GET',
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }


    let json = await response.json();
    return json;
}