import { User } from "../models/user";

export function getShopName (shopId: number, user:User): string {
    if (shopId == 0) return "Général";

    if (shopId <0) return user.groups.find(el => el.id === -1*shopId)?.name || ''

    return user.shops.find(el => el.id === shopId)?.name || ''
}

export function getGroupColorTheme (colorTheme: string | null): any {
    switch (colorTheme) {
        case "purple":
            return {
                "--bg-color": "#e4d3f891",
                "--main-color": "#a87de9",
                "--main-color-2": "#ead3fd",
                "--main-text-color": "#a87de9", // main color
                "--valueN_1-color": "#9a80af" 
            }
        case "blue2":
            return {
                "--bg-color": "#c0f0ed91",
                "--main-color": "#5eccc5",
                "--main-color-2": "#cef2f0",
                "--main-text-color": "#5eccc5", // main color
                "--valueN_1-color": "#95a9b4"
            }
        case "orange":
            return {
                "--bg-color": "#fde5d891",
                "--main-color": "#f5a278",
                "--main-color-2": "#fde5d8",
                "--main-text-color": "#e68040",
                "--valueN_1-color": "#b69b8e"
            }
        case "green": 
            return {
                "--bg-color": "#eaf8d791",
                "--main-color": "#8dc73a",
                "--main-color-2": "#eaf8d7",
                "--main-text-color": "#8dc73a", // main color
                "--valueN_1-color": "#9faa91",
            }
        case "green2": 
            return {
                "--bg-color": "#cde1d291",
                "--main-color": "#379e7b",
                "--main-color-2": "#a4dfcb",
                "--main-text-color": "#379e7b", //main color
                "--valueN_1-color": "#71817c",
            }
        case "pink":
            return {
                "--bg-color": "#f8cadc91",
                "--main-color": "#e96a9b",
                "--main-color-2": "#fcc2d8",
                "--main-text-color": "#e96a9b", // main color
                "--valueN_1-color": "#a78b95"
            }
        default:
            return {
                "--bg-color":'#d3e8f891',
                "--main-color":"#718dc2",
                "--main-color-2":"#e2e5f5",
                "--main-text-color":"#66ace6",
                "--valueN_1-color" : "#95a9b4;",
            }
    }
}