
import { CSSProperties, useRef } from "react";
import ChartData from "../../models/chartData";
import { Chart, registerables } from "chart.js";
import { formatLabels, getChartColorTheme } from "../../utils/Charts";
import useWindowDimensions, { useWindowTheme } from "../../app/hooks";
import { smallWidth } from "../../utils/Const";

Chart.register(...registerables)

interface RadarChartProps {
    data: ChartData[];
    style?: CSSProperties;
    theme: string;
}


export function RadarChart ({data, style, theme} : RadarChartProps){

    const {width} = useWindowDimensions();
    const fontSize = width < smallWidth ? 10 : 12;

    let colors = getChartColorTheme(theme, useWindowTheme() === "dark");

    style= {
        ...style,
        width:'96%', 
        display:"flex", 
        alignItems:"center", 
        justifyContent:"center"
    }

    const radarData = {
        labels: formatLabels(data.map(el => el.name),15),
        datasets: [
            {
                label : "N",
                data: data.map(el => el.valueN),
                fill: true,
                backgroundColor:  colors.backgroundN,
                borderColor:  colors.legendN,
                pointBackgroundColor:  colors.legendN,
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: colors.legendN
            }, 
            {
                label : "N-1",
                data: data.map(el => el.valueN_1),
                fill: true,
                backgroundColor: colors.backgroundN_1,
                borderColor: colors.legendN_1,
                pointBackgroundColor: colors.legendN_1,
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: colors.legendN_1
            }
        ]
    }

    const radarOptions: any = {
        plugins: {
            tooltip: {
                enabled : true,
                backgroundColor : '#fff',
                titleColor : colors.text,
                bodyColor : colors.text,
                borderWidth : 1,
                borderColor : colors.text,
                displayColors:false,
                callbacks:{
                    title: function(context: any) {
                        return context[0].label ;
                    },
                    label: function(context: any) {
                        return context.formattedValue ;
                    }
                }
    
            },
            legend: {
                display : true,
                labels: {
                    color: colors.text
                }
            }

        },
        scales: {
          r: {
            pointLabels: {
                color:colors.text,
                font: {
                    size: fontSize,
                } 
            },
            grid: {
                color: colors.line
            },
            angleLines: {
                color: colors.line
            },
            ticks: {
                color: colors.text,
                backdropColor: 'transparent'
            }
          }
        },
        elements: {
            line: {
                borderWidth: 1,
            }
        },
      };

    const chartRef = useRef<Chart | null>(null);

    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        if (!canvas || chartRef.current?.id != null) return;
        const ctx = canvas.getContext("2d");
        if (ctx) {
            chartRef.current = new Chart(ctx, {
                type: "radar",
                data: radarData,
                options: radarOptions
            });
        }
      };

    return  (
        <div style={style}>
            <canvas ref={canvasCallback}></canvas>
        </div>
    )
  
}