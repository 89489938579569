import { User } from "../models/user";


interface Credentials {
    username: string;
    password: string;
}

export async function login (credentials : Credentials): Promise<User> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(credentials),
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    let user = await response.json();
    return user;
}

export async function checkAuth (): Promise<User> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/auth/check', {
        method: 'GET',
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }
    let json = await response.json();
    return json;
}

export async function logout (): Promise<boolean> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/auth/logout', {
        method: 'GET',
        credentials: 'include',
    });

    return response.ok;
}