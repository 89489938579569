import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/UserSlice';
import dashboardReducer from '../features/DashboardSlice';


export const store = configureStore({
  reducer: {
    user: userReducer,
    dashboard: dashboardReducer
  },
});


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch