import { Widget } from "../models/widget";

export async function addWidgetToFavorites (widgetId: number, slideIndex:number | null): Promise<Widget[]> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/widgets/favorites/add', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({id:widgetId, slideIndex:slideIndex}),
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }


    let json = await response.json();
    return json;
}

export async function removeWidgetFromFavorites (widgetId: number): Promise<Widget[]> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/widgets/favorites/remove', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({id: widgetId}),
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }


    let json = await response.json();
    return json;
}

export async function updateFavoriteChartType (widgetId: number, chartType:string): Promise<Widget[]> {
    let response = await fetch(process.env.REACT_APP_SERVER_BASE_URL+'/widgets/chart/update', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({id: widgetId, chartType:chartType}),
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }


    let json = await response.json();
    return json;
}